.standardpay-user-agreement-heading-text,
.standardpay-user-agreement-body-text {
    line-height: 24px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;
}

.standardpay-user-agreement-heading-text{
    font-size: 16px;
}

.standardpay-user-agreement-heading-text {
    height: 24px;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #111112;
}

.standardpay-user-agreement-body-text,
.standardpay-user-agreement-label {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #36383A;
}

.standardpay-user-agreement-label {
    margin-top: 32px
}
