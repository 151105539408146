@import '../header-shared-styles';

.culture-select {
  display: flex;
  justify-content: flex-end;

  > button {
    @extend %lower-menu-font-styling;
    @extend %clear-button;
    padding: $lower-menu-padding $header-margin-above-breakpoint;
    display: inline-flex;
  }

  .dropdown-menu {
    left: auto; //bootstrap override
    right: $menu-gutter;
    border-radius: 0;
    padding: $space-md $space-lg;
    width: 320px;

    .disclaimer {
      font-size: 12px;
    }
  }

  @include respond-below($header-margin-breakpoint) {
    > button {
      padding: $lower-menu-padding $header-margin-below-breakpoint;
    }
  }
  @include respond-below(xxs) {
    .dropdown-menu {
      width: unset;
      left: $menu-gutter;
      right: $menu-gutter;
    }
  }
}
