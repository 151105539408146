@import 'variables';

#hazmat-validation-page {
  #hazmat-validation {
    max-width: 525px;

    h3 {
      font-weight: normal;
      margin-bottom: revert;
    }

    label {
      margin-bottom: revert;
    }

    .fax-number {
      font-weight: bold;
    }

    .button-bay {
      text-align: right;
      margin: auto 0 0 auto;
      width: auto;
    }
  }
}

.chr-modal #hazmat-validation-page {
  #hazmat-validation {
    max-width: none;

    .company-address {
      margin-bottom: 0;
    }
  }
}
