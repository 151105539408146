@import 'variables';

.agree-option-container {
  .accordion-header {
    display: flex;
    flex-direction: row;

    .agreement-name {
      width: fit-content;
      display: flex;
      margin: 0 1.5rem 0 1.5rem;
      line-height: 1.5rem;
      font-weight: 700;
    }
  }

  .content {
    .agreement-content,
    .agreement-content p {
      color: $carrier-dark-grey;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.0313rem;
      line-height: 1.5rem;
      margin: 0 0 10px;
    }

    .action-buttons-container {
      margin-top: 1.5rem;
    }
  }

  .action-buttons-container {
    display: flex;

    .accordion-header {
      display: flex;
      flex-direction: row;
    }

    button {
      margin-right: 1.5625rem;
      line-height: 1.5rem;
    }

    input[type='checkbox'] {
      margin-right: 0.5625rem;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: #555555;
    }
  }

  @include respond-below(xs) {
    .accordion-header,
    .action-buttons-container {
      display: flex;
      flex-direction: column;

      button {
        text-align: left;
      }
    }
  }
}
