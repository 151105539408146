@import 'variables';
@import 'buttons';
@import 'page-styles';

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.accordion-header {
  .glyphicon .glyphicon-menu-up,
  .glyphicon .glyphicon-menu-up {
    font-size: 0.8125rem;
    display: flex;
    justify-content: center;
    color: $chr-active-blue;
  }
}

.hidden {
  display: none;
}

.detail-accordion.accordion-wrapper {
  > .accordion-header {
    cursor: pointer;
    padding: 10px 10px 10px 15px;
    margin-bottom: 0;

    .accordion-header-button-bay {
      text-align: right;

      .mobile-modify-search-button {
        margin-top: 12px;
      }
    }

    .glyphicon {
      font-size: 10px;
      margin-top: 4px;
      float: left;
    }

    .glyphicon-menu-up {
      display: none;
    }

    .glyphicon-menu-down {
      display: inline-block;
    }
  }

  > .accordion-content {
    padding: 1em 2.2em;
    display: none;
  }

  &.is-expanded {
    overflow: visible;

    > .accordion-header {
      .glyphicon-menu-up {
        display: inline-block;
      }

      .glyphicon-menu-down {
        display: none;
      }
    }

    > .accordion-content {
      display: block;
      -webkit-animation: fadeIn 0.5s;
      animation: fadeIn 0.5s;
    }
  }
}

@media print {
  .accordion-wrapper {
    max-height: none;

    > .accordion-header {
      .glyphicon-menu-down {
        display: none;
      }

      .glyphicon-menu-up {
        display: inline;
      }
    }

    > .accordion-content {
      display: block;
    }
  }
}
