@import 'variables';

.notification-center-content {
  width: 512px;
  display: flex;
  height: 100%;

  .header {
    font-size: $font-size-md;
    color: '#111112';
    font-style: normal;
    font-weight: $bold;
  }

  .close-btn {
    background-color: transparent;

    svg {
      fill: #212121;
    }

    &:hover,
    &:focus {
      border-color: transparent;
    }
  }

  .empty-state-container {
    flex: 1;
    display: flex;

    .title {
      color: #000000;
      font-weight: $bold;
      margin-bottom: 12px;
    }

    .subTitle {
      color: #545658;
      text-align: center;
    }

    .box-flaps {
      margin-bottom: 24px;
    }
  }

  @include respond-below(sm) {
    width: 100vw;
  }
}
