@import 'variables';

.culture-select-form {
  .autocomplete {
    position: relative;

    .suggestions {
      min-width: 100%;
      max-height: 300px;
      overflow-y: auto;

      a {
        color: inherit;
        /* stylelint-disable-next-line declaration-no-important */
        font-weight: normal !important;
        border: none;
        margin: 0;

        &:hover {
          text-decoration: none;
        }

        &::before {
          content: '';
          padding-right: 19px;
        }

        &.current {
          background: $chr-light-grey;

          &::before {
            content: '\2713';
            padding-right: 10px;
          }
        }

        &.selected {
          background: $chr-active-blue;
          color: white;
        }

        &:first-child {
          padding-top: 5px;
        }
      }
    }
  }
}
