$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$base-font-partial-path: "~@chr/autoStyle/dist/";
@import "@chr/autoStyle/dist/sass/chrBootstrap_chrNavisphere";
@import "variables";
@import "print";
@import "media-queries";
@import "page-styles";
@import "fonts";

//Global page styling
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;

  a.anchor {
    color: black;

    &:hover {
      text-decoration: none;
    }
  }

  #app-routes-component {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: space-between;

    #bottom-footer {
      justify-self: flex-end;
    }

    @include respond-below(md) {
      .container {
        width: auto; //overriding bootstrap's grid width to provide more room for card/table views.
      }
    }
  }

  // Hide overall comm100, only un-hide comm100 chat bubbles and related features for North American T-code users in the code
  #comm100-container>iframe {
    display: block !important;
  }

  //IE Styles
  @include browser-only('IE-only') {
    #app-routes-component {
      .body-wrapper {
        margin-bottom: $space-xxl; //footer is now out of flow of document. This ensures the footer will never clip over page content
      }

      footer {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

//end of global page styling

.navisphere-blue {
  color: $chr-active-blue;
}

.faint {
  color: $chr-medium-grey;
}

.very-faint {
  color: $chr-light-grey;
}

.grey-background {
  background: $carrier-off-white;
}

body.has-modal {
  overflow: hidden;
}

.breakable {
  word-break: break-word;
}

/* stylelint-disable declaration-no-important */
body.no-transitions * {
  // This class (and thus these rules) are used to disable CSS transitions during Protractor/AAT tests, as they
  // cannot be accounted for when the system attempts to interact with various parts of the website.
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
  animation: none !important;
  -webkit-animation: none !important;
}

.visually-hidden {
  position: absolute !important;
  left: -9999px !important;
  top: auto;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

/* stylelint-enable declaration-no-important */

/***** BOOTSTRAP OVERRIDES *****/
.help-text.ns-icon.ns-error {
  display: none;
}

.has-error {
  .help-text.ns-icon.ns-error {
    display: block;
  }
}

.alert {
  padding: 0;
  margin-bottom: 5px;
  border: none;
}

.form-error {
  color: $chr-error-red;

  font-size: 13px;
  font-weight: 600;
}

label {
  font-weight: 600;
}

.paragraph {
  color: #363636;
  font-size: 12px;
  line-height: 1.4;
  font-weight: normal;
}

@mixin max-lines($line-count) {
  .max-lines-#{$line-count} {
    -webkit-line-clamp: $line-count;
    display: -webkit-box;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical; // autoprefix must be disabled for this property to come through
    word-break: break-word;

    @if $line-count ==1 {
      word-break: break-all;
    }
  }
}

@include max-lines(1);
@include max-lines(2);
@include max-lines(3);
@include max-lines(4);

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

legend {
  border: none;
  margin: unset;
}

.radio-selection.stacked {
  .radio-inline {
    margin-top: 12px;
  }
}

input[type="radio"],
input[type="checkbox"] {
  margin: 3px 0;
}

/***** CHR Auto-Style Overrides *****/
input[type="checkbox"]:disabled:checked::before {
  color: $chr-navisphere-medium-grey;
  font-size: 14px;
  font-weight: bolder;
  line-height: 1;
  padding-left: 0.5px;
}

input[type=radio]:not(:disabled):checked::after {
  background: #0071C7;
}

input[type=checkbox]:not(:disabled):checked::before,
input[type=radio]:not(:disabled):checked::before {
  color: #0071C7;
  border-color: #0071C7;
}

.checkbox-inline input[type="checkbox"] {
  margin-top: 3px;
}

.form-group label.radio-inline {
  display: inline-block;
}

.radio-inline+.radio-inline:last-child {
  margin-right: 0;
}

.ns-loading {
  position: absolute;
  right: 18px;
  top: 28px;
  width: 20px;
  height: 20px;
  font-size: 15px;
  color: $chr-active-blue;
}

.drop-lg {
  //DO NOT USE: Will be using autoStyle's helper classes moving forward. space-outer-top-lg
  margin-top: 35px;
}

.drop-md {
  //DO NOT USE: Will be using autoStyle's helper classes moving forward. There is not a close autoStyle class for this.
  margin-top: 25px;
}

.drop-sm {
  //DO NOT USE: Will be using autoStyle's helper classes moving forward. Try space-outer-top-md
  margin-top: 15px;
}

//There is a helper class between these sizes. Try space-outer-top-sm

.drop-xs {
  //DO NOT USE: Will be using autoStyle's helper classes moving forward. Try space-outer-top-xs
  margin-top: 5px;
}

.no-wrap {
  white-space: nowrap;
}

.full-width {
  width: 100%;
}

.width-auto {
  width: auto;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}

.display-block {
  display: block;
}

.float-right {
  float: right;
}

.space-outer-all-md {
  padding: $space-md;
}

.space-outer-all-lg {
  padding: $space-lg;
}

.hidden-xs-carrier {

  //We have a couple smaller breakpoints than bootstrap 3, so we need a custom hidden-xs so we can have a .hidden-xs.hidden-xxs
  @include respond-between(xs, sm) {
    display: none !important;
  }
}

.hidden-xxs-carrier {

  //We have a couple smaller breakpoints than bootstrap 3, so we need a custom hidden-sm and hidden-xs so we can have a .hidden-xxs
  @include respond-between(xxs, xs) {
    display: none !important;
  }
}

.hidden-mobile {
  @include respond-below(xxs) {
    display: none !important;
  }
}

.chr-modal.not-implemented .close-x {
  margin-top: -15px;
}

.xs-space-no-outer {

  //same as bootstrap xs breakpoint
  @include respond-below(sm) {
    margin: 0;
  }
}

.mobile-space-no-outer {
  @include respond-below(xxs) {
    margin: 0;
  }
}

.mobile-width-auto {
  @include respond-below(xxs) {
    width: auto;
  }
}

.mobile-block {
  @include respond-below(xxs) {
    display: block;
  }
}

.mobile-reverse-column {
  @include respond-below(xxs) {
    display: flex;
    flex-direction: column-reverse;

    //remember, last child will visually look like the first child with this
    &>*:not(:last-child) {
      margin-top: $space-sm;
    }
  }
}

.reverse-column {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.row-space-outter-sm {

  //compliments autoStyle's flex-row class
  &>*:not(:last-child) {
    margin-right: 8px;
  }

  &.flex-row {
    @include respond-below(sm) {
      &>*:not(:last-child) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
  }
}

.grey-section {
  border: 1px solid $chr-ultra-light-grey;
  text-align: center;
  background-color: $carrier-off-white;
  padding: $space-md;
}

.required {
  //Styleguide only applies this style to labels. Declaring it here so we can use for any element
  position: relative;
  padding-left: 12px;

  &::before {
    content: "*";
    position: absolute;
    background: transparent;
    color: #da0303;
    font-size: 20px;
    line-height: 15px;
    top: 5px;
    left: 0;
    border-radius: 50%;
  }
}

label.right-label {
  padding-left: 0;
  padding-top: 2px;
}

label.has-popover {
  margin-top: -4px;
  margin-bottom: -2px;
}

.hr-text {
  position: relative;
  outline: 0;
  border: 0;
  color: $chr-active-blue;
  text-align: center;
  height: 22px;
  width: 75%;

  &:before {
    content: "";
    position: absolute;
    border-top: 1px solid $chr-active-blue;
    left: 0;
    top: 50%;
    width: 100%;
    height: 2px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    color: $chr-active-blue;
    padding: 0 $space-xs;
    font-size: 16px;
    font-weight: 700;
    background-color: $carrier-off-white;
  }
}

.panel {
  border-radius: 4px;
}

.no-padding {
  padding: 0;
}

.padding-top {
  padding-top: 20px;
}

.padding-left-sm {
  padding-left: 10px;
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.display-on-focus {
  &:not(:focus) {
    //Use sr-only bootstrap class to visually hide the element. It can still be tabbed to. If you want the element to not be tabbed to, use tab-index:-1
    @extend .sr-only;
  }
}

//for edge only
@supports (-ms-ime-align: auto) {
  ul {
    padding: 0;
  }
}

@import 'buttons';

@import 'carrier-type';

@import 'controls';

.MuiGrid-root {
  input[type=radio] {
    position: absolute;
  }
}
