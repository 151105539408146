@import '../../../_variables';
@import 'containers/layout/header/header-shared-styles';
@import '../../../_media-queries';

#bottom-footer {
  border-top: 1px solid $site-header-border-color;
  background-color: $white;
  padding: $space-md $header-margin-above-breakpoint;
  display: flex;
  justify-content: space-between;
  align-content: center;
  line-height: 34px;

  .footer-links {
    text-align: center;
    margin-left: $menu-gutter;
    margin-right: $menu-gutter;

    .divide {
      padding-left: $space-sm;
    }

    a.footer-link {
      color: $chr-active-blue;
      font-weight: 600;
      text-decoration: none;
      padding-left: $space-sm;
    }

    a.footer-link:link,
    a.footer-link:visited {
      text-decoration: none;
      display: inline-block; //keeps a element from wrapping individual words
    }

    a.footer-link.selected,
    a.footer-link:focus,
    a.footer-link:active {
      text-decoration: none;
    }

    a.footer-link:hover {
      text-decoration: underline;
    }

    .btn {
      min-height: 0;
      border: none;
      padding: 0;
      line-height: 34px;
      vertical-align: unset;
    }
  }

  .footer-copyright {
    text-align: right;
    color: $carrier-dark-2-grey;

    @include respond-above(xl) {
      padding-right: 60px;
    }   
  }

  .copyright {
    padding-right: 5px;
  }

  @include respond-below(sm) {
    display: block;

    @include respond-below($header-margin-breakpoint) {
      padding: $space-md $header-margin-below-breakpoint
    }

    .footer-image {
      float: left;
    }

    .footer-links {
      text-align: right;
      margin-right: 0;
      margin-top: 0;
    }
  }
}