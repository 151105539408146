@import 'variables';

.preferences-center-prompt-modal {
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: auto;
    bottom: auto;
  }

  .modal-title {
    font-size: $font-size-lg;
    font-weight: $normal;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #212121;
  }

  .modal-body {
    font-size: $font-size-sm;
    font-weight: $normal;
    letter-spacing: 0.5px;
    color: #212121;
  }

  @include respond-below(xs) {
    .modal-body {
      font-size: $font-size-md;
    }
  }
}
