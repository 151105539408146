.payment-option-dialog-modal-heading-text,
.payment-option-dialog-modal-body-text {
    line-height: 24px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-style: normal;
}

.payment-option-dialog-modal-heading-text {
    height: 24px;
    font-size: 16px;
    letter-spacing: 0.15px;
    color: #111112;
}

.payment-option-dialog-modal-body-text {
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #36383A;
}
