@import 'variables';

#insurance-validation-page {
  #insurance-validation {
    max-width: 525px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    h3 {
      font-weight: normal;
      margin-bottom: revert;
    }

    label {
      margin-bottom: revert;
    }

    .company-contact {
      font-weight: bold;

      label {
        font-weight: normal;
      }
    }

    .button-bay {
      text-align: right;
      margin: auto 0 0 auto;
      width: auto;
    }
  }
}

.chr-modal #insurance-validation-page {
  #insurance-validation {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: none;

    .company-address {
      margin-bottom: 0;
    }
  }
}
