@import 'header-shared-styles';

.hamburger {
  background: transparent;
  border: none;
  padding: 0 $header-margin-above-breakpoint;
  height: 100%;
  @include respond-below($header-margin-breakpoint) {
    padding: 0 $header-margin-below-breakpoint;
  }

  &:hover, &:focus {
    text-decoration: none;

    .line {
      background-color: $upper-menu-hover-font-color;
    }
  }

  .line {
    width: 25px;
    height: 2.5px;
    background-color: $white;
    display: block;
    margin: 4px auto;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  &.open {
    .line:nth-child(2) {
      opacity: 0;
    }

    .line:nth-child(1) {
      -webkit-transform: translateY(6.5px) rotate(45deg);
      -ms-transform: translateY(6.5px) rotate(45deg);
      -o-transform: translateY(6.5px) rotate(45deg);
      transform: translateY(6.5px) rotate(45deg);
    }

    .line:nth-child(3) {
      -webkit-transform: translateY(-6.5px) rotate(-45deg);
      -ms-transform: translateY(-6.5px) rotate(-45deg);
      -o-transform: translateY(-6.5px) rotate(-45deg);
      transform: translateY(-6.5px) rotate(-45deg);
    }
  }
}
