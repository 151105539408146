@import 'header-shared-styles';

/*--Start of smaller independent elements--*/
.carrier-logo {
  display: flex;
  align-items: center;

  .chr-brand-svg {
    margin-left: $header-margin-above-breakpoint;
    @include respond-below($header-margin-breakpoint) {
      margin-left: $header-margin-below-breakpoint;
    }
      width: 300px;
      @include respond-below(md) {
        @at-root .site-header #{&} {
          width: 168px;
        }
      }
  }
}

.onboarding-header-logo{
  display: flex;
  align-items: center;
  img {
    height: 40px;
    width: 253px;
    margin-left: $header-margin-above-breakpoint;
    @include respond-below($header-margin-breakpoint) {
      margin-left: $header-margin-below-breakpoint;
    }
  }
}

.user-info {
  display: flex;
  color: $upper-menu-font-color;

  svg {
    fill: $upper-menu-font-color;
    margin-right: $space-sm;
    height: 19px;
    width: 19px;
  }
}

.notification-btn {
  svg {
    fill: $upper-menu-font-color;
    height: 19px;
    width: 19px;
  }
}

.navbar-load-count {
  border-radius: 10px;
  margin: 0 -5px 0 5px;
  padding: 1px 6px 1px 9px;
  background: $chr-error-red;
  color: white;
  text-align: center;
  font-weight: 600;

  span { //we shouldn't use a span here. When we update this styling lets use something more specific
    line-height: 1.1;
    margin-left: -2px;
  }
}

/*--End of smaller independent elements--*/
/*--Start of navigation/menu styling--*/
.upper-menu {
  background: $upper-menu-background-color;
  height: 60px;
  display: flex;
  justify-content: space-between;
}

.external-header {
  .upper-menu {
    a {
      @extend %upper-menu_font_styling;
      padding-left: $menu-gutter;
      padding-right: $header-margin-above-breakpoint;
      display: flex;
      align-items: center;
      @include respond-below($header-margin-breakpoint) {
        padding-right: $header-margin-below-breakpoint
      }
    }
  }
}

.internal-header {
  /* stylelint-disable-next-line */
  /*--Start of site-header styles(visible on all viewport sizes >768px)--*/
  .site-header {
    margin-bottom: $space-lg;

    .lower-menu, .upper-menu {
      > ul {
        padding-inline-start: 0; //overriding Chrome's left padding it applies on ul elements
        margin-bottom: 0; // overriding autoStyle/Bootstrap style

        display: flex;
        flex-wrap: wrap;

        > li {
          list-style: none;

          > a {
            display: block;
          }

          > a, > button {
            padding-left: $menu-gutter;
            padding-right: $menu-gutter;
            height: 100%; //aligns active state's orange bar at bottom of li element
          }

          > button {
            @extend %clear-button;
          }
        }
      }
    }

    .upper-menu {
      .user-info {
        padding-right: $header-margin-above-breakpoint;

        &:hover, &:focus {
          svg {
            fill: $upper-menu-hover-font-color;
          }
        }
      }

      & > ul {
        > li {
          > a, > button {
            @extend %upper-menu_font_styling;
            height: 100%;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .lower-menu {
      background: $lower-menu-background-color;
      border-bottom: 1px solid $site-header-border-color;

      & > ul {
        margin-left: $menu-gutter;
        margin-right: $menu-gutter;

        > li {
          &:hover, &:focus {
            background: $lower-menu-hover-background-color;
            text-decoration: none;
          }

          > a, > button {
            @extend %lower-menu-font-styling;
            padding-top: $lower-menu-padding;
            padding-bottom: $lower-menu-padding;
            border-bottom: transparent solid 2px; //will be filled in with color when active
            &:active, &.active {
              border-bottom-color: $chr-navisphere-light-blue;
            }
          }
        }
      }

      .main-menu_my-loads {
        position: relative;
      }
    }
  }
  /*--End of site-header styles(visible on all viewport sizes >768px)--*/
  /*--Start of mobile-header styles (visible on all viewport sizes <768px)--*/
  .mobile-header {
    display: none;
    background: $upper-menu-background-color;

    .mobile-menu {
      display: none;

      &.open {
        display: block;
      }
    }

    .notification-btn {
      background-color: transparent;
      border: none;
      
      svg {
        fill: $upper-menu-font-color;
        color: $upper-menu-font-color;
        height: 19px;
        width: 19px;
      }
    }

    ul {
      padding-inline-start: 0; //overriding Chrome's left padding it applies on ul elements
      margin-bottom: 0; // overriding autoStyle/Bootstrap style
      list-style: none;

      .user-info {
        padding: $space-md $space-lg;
      }

      li {
        a, button {
          @extend %upper-menu_font_styling;
          display: block;
          padding: $space-md $space-lg;
          border-bottom: 1px solid $mobile-header-border-color;
          border-left: 4px solid transparent; //will be filled in with color when active
          &:active, &.active {
            border-left-color: $chr-navisphere-light-blue;
          }

          &:hover, &:focus {
            text-decoration: none;
            background: $upper-menu-hover-background-color;
          }
        }

        button {
          @extend %clear-button;
          width: 100%;
          text-align: left;
        }

        &.secondary-menu-item { //site-header's secondary-menu items are styled differently than rest of menu
          a, button {
            border: 0;
            border-left: 4px solid transparent;
            font-weight: $normal;

            &:not(:disabled):not(.disabled):hover,
            &:not(:disabled):not(.disabled):focus { //autostyle/ general button styles override
              border: 0;
              border-left: 4px solid transparent;
            }
          }
        }
      }
    }
  }
  /*--End of mobile-header styles(visible on all viewport sizes <768px)--*/
  /*--Start of responsive breakpoints--*/
  @include respond-below(sm) {
    .site-header {
      display: none;

      .upper-menu .user-info {
        @include respond-below($header-margin-breakpoint) {
          padding-right: $header-margin-below-breakpoint
        }
      }
    }

    .mobile-header {
      display: block;
    }
  }
  /* stylelint-disable-next-line */
  /*--End of responsive breakpoints--*/
}

/*--Start of IE 10+ styling--*/
@include browser-only('IE-only') {
  .upper-menu, .lower-menu {
    ul {
      padding-left: 0; //overriding IE browser style
    }
  }

  .mobile-header {
    ul {
      padding-left: 0; //overriding IE browser style
    }
  }
}

/*--End of IE 10+ styling--*/
/*--Start of Firefox styling--*/
.header-component button:-moz-focusring {
  outline-width: 1px;
  outline-style: dotted;
}

.help-and-faq-container {
  display: flex !important;
  border-top: 1px solid $site-header-border-color;
  .help-and-faq-item {
    border-top: none !important;
    width: auto;
    margin-right: 5px;
  }
}

/*--End of Firefox styling--*/
/*--End of navigation/menu styling--*/