@import 'variables';
@import 'media-queries';

.manage-drivers {
  .user-icon {
    fill: $carrier-mid-2-grey;
  }

  .data-table td button.btn-default, .data-table td button.btn-primary { // usually buttons would take up whole column. In this case, we have 2 inline buttons.
    width: auto;
  }

  .grid-view table.data-table tbody td {
    vertical-align: middle;
  }

  .ns-card.flow-card {
    padding: $space-md; // this card is not utiliziing 'river' section, so it does not need the modified bottom padding and should be uniform on all 4 sides
    .card-content {
      display: flex;
      @include respond-below(xs) {
        display: block;
        margin-bottom: $space-md;
        .card-content-info {
          display: inline;
        }
      }
    }
  }


}
