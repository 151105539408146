@font-face {
 font-family: 'Titillium Web';
 src: url('/src/assets/fonts/TitilliumWeb-Regular.woff') format('woff');
 font-weight: 200;
 font-style: normal;
 font-display: swap;
}

@font-face {
 font-family: 'Titillium Web';
 src: url('/src/assets/fonts/TitilliumWeb-Regular.woff') format('woff');
 font-weight: 400;
 font-style: normal;
 font-display: swap;
}

@font-face {
 font-family: 'Open Sans';
 src: url('/src/assets/fonts/OpenSans-VariableFont_wdth_wght.woff') format('woff');
 font-weight: 300 700;
 font-style: normal;
 font-display: swap;
}

@font-face {
 font-family: 'Open Sans';
 src: url('/src/assets/fonts/OpenSans-Italic-VariableFont_wdth_wght.woff') format('woff');
 font-weight: 300 700;
 font-style: italic;
 font-display: swap;
}

@font-face {
 font-family: 'Material Icons';
 font-style: normal;
 font-weight: 400;
 src: url('/src/assets/fonts/Material-Icons.woff2') format('woff2');
}

@font-face {
 font-family: 'Material Icons Outlined';
 font-style: normal;
 font-weight: 400;
 src: url('/src/assets/fonts/Material-Icons-Outlined.woff2') format('woff2');
}

@font-face {
 font-family: 'Material Icons Round';
 font-style: normal;
 font-weight: 400;
 src: url('/src/assets/fonts/Material-Icons-Round.woff2') format('woff2');
}

@font-face {
 font-family: 'Material Icons Sharp';
 font-style: normal;
 font-weight: 400;
 src: url('/src/assets/fonts/Material-Icons-Sharp.woff2') format('woff2');
}

@font-face {
 font-family: 'Material Icons Two Tone';
 font-style: normal;
 font-weight: 400;
 src: url('/src/assets/fonts/Material-Icons-Two-Tone.woff2') format('woff2');
}

body {
 --google-font-color-materialiconstwotone: none;
}

.material-icons {
 font-family: 'Material Icons';
 font-weight: normal;
 font-style: normal;
 font-size: 24px;
 line-height: 1;
 letter-spacing: normal;
 text-transform: none;
 display: inline-block;
 white-space: nowrap;
 word-wrap: normal;
 direction: ltr;
 font-feature-settings: 'liga';
 -webkit-font-feature-settings: 'liga';
 -webkit-font-smoothing: antialiased;
}

.material-icons-outlined {
 font-family: 'Material Icons Outlined';
 font-weight: normal;
 font-style: normal;
 font-size: 24px;
 line-height: 1;
 letter-spacing: normal;
 text-transform: none;
 display: inline-block;
 white-space: nowrap;
 word-wrap: normal;
 direction: ltr;
 font-feature-settings: 'liga';
 -webkit-font-feature-settings: 'liga';
 -webkit-font-smoothing: antialiased;
}

.material-icons-round {
 font-family: 'Material Icons Round';
 font-weight: normal;
 font-style: normal;
 font-size: 24px;
 line-height: 1;
 letter-spacing: normal;
 text-transform: none;
 display: inline-block;
 white-space: nowrap;
 word-wrap: normal;
 direction: ltr;
 font-feature-settings: 'liga';
 -webkit-font-feature-settings: 'liga';
 -webkit-font-smoothing: antialiased;
}

.material-icons-sharp {
 font-family: 'Material Icons Sharp';
 font-weight: normal;
 font-style: normal;
 font-size: 24px;
 line-height: 1;
 letter-spacing: normal;
 text-transform: none;
 display: inline-block;
 white-space: nowrap;
 word-wrap: normal;
 direction: ltr;
 font-feature-settings: 'liga';
 -webkit-font-feature-settings: 'liga';
 -webkit-font-smoothing: antialiased;
}

.material-icons-two-tone {
 font-family: 'Material Icons Two Tone';
 font-weight: normal;
 font-style: normal;
 font-size: 24px;
 line-height: 1;
 letter-spacing: normal;
 text-transform: none;
 display: inline-block;
 white-space: nowrap;
 word-wrap: normal;
 direction: ltr;
 font-feature-settings: 'liga';
 -webkit-font-feature-settings: 'liga';
 -webkit-font-smoothing: antialiased;
}
