@import "variables";

.animationMainContainer {
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    @include respond-below(lg) {
        height: 100%;
    }
    .Logincontainer {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;
        // background-position: center;
        // background-size: cover;
        @include respond-below(lg) {
            flex-direction: column;
        }

        .leftContainer {
            width: 60%;
            height: auto;
            position: relative;
            overflow: hidden;
            @include respond-below(lg) {
                width: 100%;
            }
        }

        .rightContainer {
            width: 40%;
            min-height: 100vh;
            background-color: #ffffff;
            overflow: auto;
            display: flex;
            flex-direction: column;
            @include respond-below(lg) {
                width: 100%;
            }        
            @include respond-above(lg){
                background-color: #ffffff;
            }
            .culture-select {
                width: 100%;
                #btn-change-culture {
                    position: absolute;
                    right: 0; //for IE
                    font-weight: $normal;
                    @include respond-below(sm) {
                        right: 0;
                    }
                }
                .dropdown-menu {
                    margin-top: 40px;
                }
            }
        }
    }
}

.download-box {
    padding-bottom: 64px;
    margin-bottom: 32px;
    @include respond-below(lg) {
        display: none !important;
    }
}

.img-banner-large {
    height: 100vh;
    object-fit: cover;
    object-position: top;
    @include respond-below(lg) {
        display: none;
    }
}

.img-banner-mid {
    display: none;
    @include respond-below(lg) {
        display: block;
        width: 100%;
    }
    @include respond-below(xs) {
        display: none;
    }
}

.img-banner-small {
    display: none;
    @include respond-below(xs) {
        display: block;
        width: 100%;
    }
}

.pausePlayContainer {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    margin: 24;
    @include respond-below(lg) {
        display: none;
    }
}
.playBtn {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    column-gap: 8px;
    background-color: transparent;
    color: #f2f2f2;
    border: none;
    cursor: pointer;
    padding: 16px 24px;
    font-size: $font-size-sm;
    @include respond-above(xxl) {
        font-size: $font-size-md;
    }
}