@import 'variables';
@import 'media-queries';

.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2000;

  .loading-indicator-background {
    background: $carrier-dark-2-grey;
    opacity: .8;
    height: 100%;
    width: 100%;
  }

  &.inline {
    position: absolute;

    .loading-indicator-background {
      position: absolute;
    }

    .loading-indicator-body {
      h3 {
        display: none;
      }
    }
  }

  .loading-indicator-body {
    position: absolute;
    left: 10%;
    top: 40%;
    width: 80%;
    border-radius: 4px;
    background: $chr-dark-grey;
    text-align: center;
    padding: 25px 15px;
    box-shadow: 5px 5px 8px 0 rgba(0, 0, 0, 0.16);

    .loading-title {
      color: $white;
      margin: 0;
    }
  }
}

.spinner {
  .sk-cube-grid {
    width: 40px;
    height: 40px;
    margin: 10px auto;
  }

  .sk-cube-grid .sk-cube {
    width: 33%;
    height: 33%;
    background-color: $chr-active-blue;
    float: left;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  }

  .sk-cube-grid .sk-cube1 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .sk-cube-grid .sk-cube2 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .sk-cube-grid .sk-cube3 {
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }

  .sk-cube-grid .sk-cube4 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  .sk-cube-grid .sk-cube5 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  .sk-cube-grid .sk-cube6 {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .sk-cube-grid .sk-cube7 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }

  .sk-cube-grid .sk-cube8 {
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
  }

  .sk-cube-grid .sk-cube9 {
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
  }

  @-webkit-keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }

    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

  @keyframes sk-cubeGridScaleDelay {
    0%, 70%, 100% {
      -webkit-transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }

    35% {
      -webkit-transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }
}

@include respond-above(sm) {
  .loading-indicator {
    .loading-indicator-body {
      left: 30%;
      top: 40%;
      width: 40%;
      padding: 25px 15px;
    }
  }
}
