@import '../../../_variables';
@import '../../../_media-queries';
@import '../../../_page-styles';


.page-header {
  margin: 0 !important;
  padding-bottom: 0 !important; // autostyle override
  border-bottom-width: 0 !important; // autostyle override
}
.page-header-wrapper {
  margin-bottom: $space-md;

  @include respond-below(xxs) {
    .button-bay_mobile-responsive {
      margin-top: $space-md;
    }
  }
}
