@import 'media-queries';

.pagination-with-banner {
  margin-bottom: 100px;
}

.pagination-container {
  padding: 8px 0 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .page-size-select {
      padding-bottom: 12px;
    }
  }

  .MuiPaginationItem-root.Mui-selected {
    background-color: rgba(0, 113, 199, 0.08) !important;
    color: #111112;
  }

  .MuiPaginationItem-root {
    color: #0071c7;
  }

  .page-size-select {
    display: flex;
    align-items: center;

    .select-label {
      padding-right: 8px;
    }

    #page-size-select-label {
      display: none;
    }

    #page-size-select {
      width: 91px;
      padding: 10px 0px 10px 16px;
    }

    .row-number-display {
      padding-left: 12px;

      .rows-display {
        padding: 0px 8px;
      }

      .total-rows {
        padding-left: 8px;
      }
    }
  }
}
