@import 'variables';
@import 'media-queries';

.chr-not-implemented {
  .notice {
    text-align: center;
    max-width: 465px;
    margin: 15px auto 0;

    h1, h2 {
      text-transform: uppercase;
      margin: 0;
      padding: 0;
      line-height: 1;
    }

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 25px;
      margin-top: 12px;
      color: $carrier-mid-2-grey;
    }

    p {
      margin: 0;
      padding: 30px 40px;
      font-size: 22px;
      line-height: 1.25;
      color: $carrier-med-grey;
    }

  }

  p {
    color: #222222;
    font-size: 14px;
  }

  @include respond-below(xxs) {
    .notice {
      padding: 16px;
      margin: 0;

      h2 {
        font-size: 26px;
      }

      p {
        font-size: 15px;
        padding: 36px 40px;
      }
    }
  }
}
