@import 'variables';

$input-padding: 0.5rem;

input[type=text]:not(.MuiOutlinedInput-input),
input[type=number]:not(.MuiOutlinedInput-input),
select:not([multiple]) {
  font-weight: $normal;
  padding: $input-padding; //using rem so input gets bigger as text gets bigger
  border: 1px solid darkgrey;
  font-size: $font-size-sm;
  line-height: ($font-size-sm * $line-height-scale);
  height: $input-height;
}

.form-control {
  font-weight: $normal;
  padding: $input-padding; //using rem so input gets bigger as text gets bigger
  border: 1px solid darkgrey;
  font-size: $font-size-sm;
  line-height: ($font-size-sm * $line-height-scale);
  height: $input-height;
}

textarea.form-control,
select[multiple].form-control {
  height: unset; // these elements need multiple lines/ to be bigger than normal input/control
}

select {
  padding: 0.43rem 0.5rem;

  option {
    font-size: $font-size-xs;
  }

  &.form-control {
    padding: 0.43rem 0.5rem;

    option {
      font-size: $font-size-xs;
    }
  }
}

select::-ms-expand {
  border: 0;
  background-color: $white;
}

input[type=text]::placeholder,
input[type=number]::placeholder,
input[type=password]::placeholder input[type=text].form-control::placeholder,
input[type=number].form-control::placeholder,
input[type=password].form-control::placeholder {
  color: $placeholder-font-color;
  font-size: $font-size-xs;
  line-height: ($font-size-sm * $line-height-scale);
}

//For IE 10-11
input[type=text]:-ms-input-placeholder,
input[type=number]:-ms-input-placeholder,
input[type=text].form-control:-ms-input-placeholder,
input[type=number].form-control:-ms-input-placeholder {
  color: $placeholder-font-color;
  font-size: $font-size-xs;
  line-height: ($font-size-sm * $line-height-scale);
}

//for Microsoft Edge
input[type=text]::-ms-input-placeholder,
input[type=text].form-control::-ms-input-placeholder {
  color: $placeholder-font-color;
  font-size: $font-size-xs;
}

//for Firefox
input[type=text]::-moz-placeholder,
input[type=text].form-control::-moz-placeholder {
  line-height: ($input-height - ($input-padding*2)); // in Firefox the line-height must be the same as the input height to have centered text
}

.input-group {
  .input-group-btn {
    button {
      height: $input-height;
      min-height: unset; // override of carrier buttons. We need buttons in input groups to be the same size as inputs
      white-space: nowrap; // if the height is set, wrapped content may wrap out of element.
    }
  }
}

@mixin use-fancy-scrollbars() {

  *::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $carrier-off-white;
  }

  *::-webkit-scrollbar {
    width: 12px;
    background-color: $carrier-off-white;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $carrier-mid-1-grey;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: $carrier-mid-2-grey;
  }

}