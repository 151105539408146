@import 'variables';

.notification-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
    border-bottom: solid 1px #CCCCCC;
    padding-bottom: 12px;
    padding-top: 16px;

    .notification-row-title {
        font-size: 14px;
        font-weight: $bold;
        line-height: 24px;
        letter-spacing: 0.5px;
    }

    .notification-row-subtitle, .notification-row-notify {
        font-size: 12px;
        font-weight: $normal;
        color: $carrier-mid-3-grey;
        letter-spacing: 0.4px;
    }

    .notification-row-notify {
        padding: 8px 0px 8px 0px;
        gap: 10px;

        li {
            font-weight: $bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
        }
    }
}
