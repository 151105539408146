@import 'variables';

.preferences-feedback {
  margin-bottom: 24px;
  margin-top: 24px;

  @include respond-below(xs) {
    .feedback-container {
      width: 100%;
    }
  }
}
