@import 'variables';

.preferences-call-to-actions {
  .cta-buttons {
    display: flex;
    gap: 8px;
  }

  .cta-container {
    display: flex;
    padding: 16px 24px;
    align-items: center;
    justify-content: space-between;
  }

  @include respond-below(xs) {
    .cta-container {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    .cta-buttons {
      flex-direction: column-reverse;
      width: 100%;
    }
  }
}
