@import 'variables';

.underlined {
  text-decoration: underline;
}

.font-lighter { //DO NOT USE, CONSULT UX BEFORE USING
  font-weight: $lighter;
}

.font-light {
  font-weight: $light;
}

.font-normal {
  font-weight: $normal;
}

.font-bold, b, strong {
  font-weight: $bold;
}

.font-bolder {
  font-weight: $bolder;
}

.help-text {
  font-size: $font-size-xs;
  font-weight: $normal;
  color: $de-emphasized-font-color;
}

.emphasize-text {
  font-size: $font-size-xs;
  text-transform: uppercase;
  font-weight: $normal;
  color: $de-emphasized-font-color;
  letter-spacing: 0.125rem;
}

html {
  font-size: 100%; //Accessibility: Allows user to set base font-size for browser. Most browser's default base font is 16px
}

body {
  font-size: $font-size-sm;
  font-weight: $normal;
  line-height: ($font-size-sm * $line-height-scale);
}

p {
  font-size: $font-size-sm;
  font-weight: $normal;
  line-height: ($font-size-sm * $line-height-scale);
  margin: 0;
  padding: 0;
}

.font-size-xxl {
  font-size: $font-size-xxl;
  line-height: ($font-size-xxl * $line-height-scale);
}

.font-size-xl {
  font-size: $font-size-xl;
  line-height: ($font-size-xl * $line-height-scale);
}

.font-size-lg {
  font-size: $font-size-lg;
  line-height: ($font-size-lg * $line-height-scale);
}

.font-size-md {
  font-size: $font-size-md;
  line-height: ($font-size-md * $line-height-scale);
}

.font-size-sm {
  font-size: $font-size-sm;
  line-height: ($font-size-sm * $line-height-scale);
}

.font-size-xs {
  font-size: $font-size-xs;
  line-height: ($font-size-xs * $line-height-scale);
}

h1, .h1 {
  @extend %h1;
  margin: 0;
  padding: 0;
}

h2, .h2 {
  @extend %h2;
  margin: 0;
  padding: 0;
}

h3, .h3 {
  @extend %h3;
  margin: 0;
  padding: 0;
}

h4, .h4 {
  @extend %h4;
  margin: 0;
  padding: 0;
}

h5, .h5 {
  @extend %h5;
  margin: 0;
  padding: 0;
}

h6, .h6 {
  @extend %h6;
  margin: 0;
  padding: 0;
}

label {
  font-size: $font-size-xs;
  font-weight: $normal;
  line-height: ($font-size-xs * $line-height-scale);
  color: $de-emphasized-font-color;
  padding: 0;
  margin: 0;
}

li {
  padding: 0;
  margin: 0;
}
