@import 'variables';

#not-authorized {
  background: $carrier-off-white;
  padding: 12px;

  h3 {
    margin-top: 0;
  }
}
