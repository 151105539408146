@import 'header-shared-styles';
@import 'variables';

.utility-nav {
  position: relative;

  .utility-menu {
    z-index: 1001;
    width: 300px;
    right: 0;
    position: absolute;
    font-size: 14px;
    border: 1px solid $site-header-border-color;
    background: $lower-menu-background-color;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); //bootstrap's dropdown value for box-shadow
    display: none;

    &.open {
      display: block;
    }

    .utility-menu-header {
      @extend %h3;
      font-weight: $normal;
      padding: $space-md;
    }

    ul {
      padding-inline-start: 0; //overriding Chrome's left padding it applies on ul elements
      li {
        list-style: none;

        a, button {
          @extend %h6;
          display: block;
          padding: $space-md;
          border-top: 1px solid $site-header-border-color;
          font-weight: normal;

          &:hover, &:focus {
            border-top: 1px solid $site-header-border-color; //keep border on hover
            text-decoration: none;
            background: $lower-menu-hover-background-color;
          }
        }

        button {
          border-left: none;
          border-right: none;
          border-bottom: none;
          background: transparent;
          width: 100%;
          text-align: left;
        }
      }
    }

  }
}

@include browser-only('IE-only') {
  .utility-nav {
    ul {
      padding-left: 0; //overriding IE browser style
    }
  }
}
