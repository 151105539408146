@import '../../../_variables';
@import '../../../_buttons';
@import '../../../_media-queries';

$header-margin-above-breakpoint: $space-lg;
$header-margin-below-breakpoint: $space-md;
$header-margin-breakpoint: sm;
$lower-menu-padding: 10px;
$menu-gutter: $space-md;

$upper-menu-background-color: #36383A;
$upper-sub-menu-background-color: #36383A;
$upper-menu-hover-background-color: darken($upper-menu-background-color, 2%); //#162329;
$upper-sub-menu-hover-background-color: darken($upper-sub-menu-background-color, 2%);
$upper-menu-font-color: $white;
$upper-menu-hover-font-color: $carrier-light-grey;

$lower-menu-background-color: $white;
$lower-menu-hover-background-color: $carrier-off-white;
$lower-menu-font-color: $carrier-dark-grey;

$site-header-border-color: $carrier-light-2-grey;
$mobile-header-border-color: $carrier-mid-2-grey;

/*--Start of placeholder classes--*/
%upper-menu_font_styling { //needed to de-couple upper-menu and the upper-menu's font styling. Menu items are not always children of .upper-menu
  font-size: $font-size-sm;
  font-weight: $normal;
  color: $upper-menu-font-color;
  letter-spacing: 0.025rem;
  white-space: nowrap;

  &:hover, &:focus {
    color: $upper-menu-hover-font-color;
    text-decoration: none;
  }
}

%lower-menu-font-styling { //needed to de-couple lower-menu and the lower-menu's font styling. Menu items are not always children of .lower-menu
  font-size: $font-size-sm;
  font-weight: $normal;
  color: $font-color;
  letter-spacing: 0.025rem;
  white-space: nowrap;

  &:hover, &:focus {
    text-decoration: none;
  }
}

/*--End of placeholder classes--*/
