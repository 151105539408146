@import 'media-queries';

.notifications-preferences-setting {
  color: #111112;
  max-width: 1200px;

  .finance-setting-section-heading {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 1rem;
  }

  .finance-setting-section-title {
    font-weight: 600;
    font-size: 18px;
  }
}
