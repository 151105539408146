@import 'variables';

.box {
  max-width: 400px;
  margin-top: $space-lg;
  margin-left: auto;
  margin-right: auto;
  padding: $space-xl;
  background: white;
  border: 1px solid $chr-placeholder-grey;
  box-shadow: 0 0 4px rgba(90, 90, 90, .1);

  .forgot-link {
    float: right;
    align-self: end;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    @include respond-below(lg) {
      row-gap: 16px;
    }

    .btn.btn-block {
      margin-top: 0;
    }
  }
}

.login-input-box {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.login-form-group {
  display: flex;
  row-gap: 4px;
  flex-direction: column;
 
  .form-group {
    margin-bottom: 0;
  }
}

.form-fields {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.login-view {
  border: none;
  box-shadow: none;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
}

@include respond-below(lg) {
  .download-app-buttons {
    display: none !important;
  }
}


.box.login-box.login-view {
  width: 100%;
  @include respond-below(xs) {
    margin: 0;
    padding-inline: 0;
    padding-top: 45px;
  }
}

.mainLogoContainer {
  @include respond-below(lg) {
    display: none;
  }
}