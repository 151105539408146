@import "variables";
@import 'media-queries';
@import 'page-styles';

.culture-select-form {
    .radio-inline + .radio-inline {
        @include respond-above(sm) {
            margin-left: 0 !important;
        }

        @include respond-between(sm, lg) {
            margin-top: $space-xs;
        }
    }

    .time-zone-select {
        padding-top: 17px;
    }

    .time-format-radio {
        padding-top: 15px;
    }
}

.account-form-first-row {
    margin-top: 15px;
}

.email-text {
    text-transform: uppercase;
    font-weight: 600;
    color: $chr-placeholder-grey;
    font-size: $font-size-sm;
    padding: $space-xs $space-md;
}

//Saved checkmark
.saved-text {
    color: $chr-validation-green;
    font-weight: bold;
    -webkit-animation: fadeinout 4s linear forwards;
    animation: fadeinout 4s linear forwards;

    .glyphicon {
        margin-right: $space-xs;
    }
}

.profile-settings {
    max-width: 1200px;
}
