@import "variables";
@import 'media-queries';

.detail-popup-wrapper {
  margin: $space-lg;

  @include respond-below(sm) { //at bootstrap's smallest breakpoint, have main content flush with edge of window
    margin-left: 0;
    margin-right: 0;
  }
}
