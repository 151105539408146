@import 'variables';
@import 'media-queries';

.modal-title {
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
}

.modal-description {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5rem;

  color: $carrier-dark-grey;
}

.detail-accordion {
  margin-top: 1.125rem;
}
