@import '../header-shared-styles';

.sub-menu-trigger {
  display: flex;
  justify-content: space-between;
}

.sub-menu {
  display: none;

  &.open {
    display: block;
  }
}

.site-header {
  .sub-menu-trigger {
    @extend %lower-menu-font-styling;
    padding-top: $lower-menu-padding;
    padding-bottom: $lower-menu-padding;
    border-bottom: transparent solid 2px; //will be filled in with color when active
    &:active, &.active {
      border-bottom-color: $chr-navisphere-light-blue;
    }

    .glyphicon {
      padding-left: $space-sm;
      padding-top: $space-xxs;
      font-size: 0.75rem;
    }
  }

  ul.sub-menu.open {
    position: absolute;
    background: $lower-menu-background-color;
    z-index: 999;
    list-style: none;
    padding-inline-start: 0; //overriding Chrome's left padding it applies on ul elements
    margin-left: $menu-gutter;
    border: 1px solid $site-header-border-color;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175); //bootstrap's dropdown value for box-shadow
    li {
      a {
        @extend %lower-menu-font-styling;
        white-space: normal;
        display: block;
        padding: $lower-menu-padding $space-md;
        border-bottom: 1px solid $site-header-border-color;

        &:hover, &:focus {
          background: $lower-menu-hover-background-color;
        }
      }
    }
  }
}

.mobile-header {
  .sub-menu-trigger {
    display: flex;

    a {
      width: 100%;
      border-bottom: none;
    }

    .glyphicon {
      width: auto;
      padding-left: $menu-gutter;
      padding-right: $menu-gutter;
      border-left: 0;
      top: 0; //boostrap override
    }

    &:hover, &:focus {
      .glyphicon {
        background: $upper-menu-hover-background-color;
      }
    }

  }

  ul.sub-menu.open {
    li {
      a {
        font-weight: $normal;
        padding-left: $space-xl;
        background: $upper-sub-menu-background-color;

        &:hover, &:focus {
          background: $upper-sub-menu-hover-background-color;
        }
      }
    }
  }
}
