@import '../../../variables';
@import 'media-queries';

.chr-modal.feedback {
  color: #363636;
}

.chr-feedback {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
}

#carrier-web-feedback-form {
  height: 600px;
  
  @include respond-below(sm) {
    height: auto;
  }
}
