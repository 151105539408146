@import 'variables';
@import 'media-queries';

.teaching-callout-container {
  z-index: 10;

  .hidden {
    display: none;
  }

  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $chr-active-blue;
    width: 100%;
    height: 108px;
    padding-left: 32px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 16px;
  }

  .cube {
    display: none;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .header {
    span {
      font-family: 'Open Sans', sans-serif;
      font-weight: $bold;
      font-size: $font-size-sm;
      line-height: 24px;
      color: #FFFFFF;
    }

    b {
      font-weight: $bolder;
    }
  }

  #btn-teaching-callout-close {
    background: transparent;
    border: none;
    width: auto;
    margin: 0;
    padding: 8px;
    min-height: auto;
    outline: none !important;
    box-shadow: none !important;
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
  }

  #btn-teaching-callout-link {
    background: transparent !important;
    border-color: #FFFFFF !important;
    margin: 0;
    padding: 2px 6px;
    width: auto;
    min-height: auto;
  }

  .link-button {
    font-family: 'Open Sans', sans-serif;
    font-weight: $bold;
    font-size: $font-size-xxs;
    line-height: 16px;
    letter-spacing: 0.8px;
    color: #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    .arrow {
      font-size: 10px;
      color: #FFFFFF;
      transform: rotate(180deg);
      margin-left: 4px;
    }
  }

  @include respond-above(sm) {
    position: absolute;
    left: 25px;

    .main-container {
      border-radius: 5px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
      width: 272px;
      height: 126px;
      padding-left: 24px;
    }

    .cube {
      display: block;
      position: absolute;
      background-color: $chr-active-blue;
      width: 18px;
      height: 18px;
      transform: rotate(45deg);
      left: 14px;
      top: -7px;
    }

    .header {
      margin-top: 10px;
    }

    #btn-teaching-callout-close {
      margin-right: -8px;
    }

  }

}
